<template>
  <div :class="$style.root">
    <VForm v-model="valid" lazy-validation  ref="form" @submit.prevent="onSave">
      <SectionCard label="Информация о пользователе">
        <div class="mb-5">
          <div :class="$style.header">Контактные данные</div>
          <VRow>
            <VCol v-for="key in ['LAST_NAME', 'NAME', 'SECOND_NAME']" cols="12" md="4" :key="key">
              <ProfileValue v-bind="fields[key]" @update:values="(values) => fields[key]['values'] = values" />
            </VCol>
            <VCol v-for="key in ['EMAIL', 'PERSONAL_PHONE']" cols="12" md="6" :key="key">
              <ProfileValue v-bind="fields[key]" @update:values="(values) => fields[key]['values'] = values" />
            </VCol>
          </VRow>
        </div>
      </SectionCard>
      <SectionCard label="Пароль для входа">
        <VRow>
          <VCol cols="12" md="4">
            <PasswordField label="Введите пароль" v-model="fields['PASSWORD']['values'][0]" :rules="rules.password" />
          </VCol>
          <VCol cols="12" md="4">
            <PasswordField label="Подтверждение пароля" v-model="fields['NEW_PASSWORD_DUPLICATE']['values'][0]" :rules="[(v) => v === fields['PASSWORD']['values'][0] || 'Пароли не совпадают']" />
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" md="4">
            <div class="mb-2">
              <VImg :src="img" width="180" height="40" />
            </div>
            <VTextField v-model="captcha" label="Введите слово на картинке" outlined dense hide-details="auto" :rules="[(v) => !!v || 'Обязательное поле']" />
          </VCol>
        </VRow>
      </SectionCard>
      <SectionCard label="Соглашения">
        <VDialog max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <VCheckbox v-model="fields['UF_EVRAZ_PD_AGREE']['values'][0]" class="mt-0 pt-0" hideDetails="auto">
              <template v-slot:label>
                <div @click.prevent :class="$style.link" v-bind="attrs" v-on="on">{{fields['UF_EVRAZ_PD_AGREE']['label']}}</div>
              </template>
            </VCheckbox>
          </template>
          <AgreeCard />
        </VDialog>
      </SectionCard>
      <div class="mt-3 grow">
        <VBtn color="primary" class="mr-2" depressed :disabled="pending || !valid" @click="onSave">Зарегистрироваться</VBtn>
      </div>
    </VForm>
  </div>
</template>

<script>
import {get, toString, map, omitBy, isEmpty, last, split, compact} from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
import { inn } from '@/api/dadata';
import SectionCard from '@/components/user/SectionCard/SectionCard';
import ProfileValue from '@/components/user/Profile/components/ProfileValue';
import AgreeCard from '@/components/general/AgreeCard/AgreeCard';
import PasswordField from '@/components/user/Profile/components/PasswordField';

export default {
  name: 'Registration',
  components: {
    PasswordField,
    AgreeCard,
    SectionCard,
    ProfileValue,
  },
  props: {
    img: { type: String },
    values: { type: Object },
  },
  data() {
    return {
      valid: true,
      captcha: '',
      rules: {
        password: [
          (v) => !!v || 'Обязательно для заполнения',
          (v) => v.length >= 10 || 'Минимум 10 символов',
          (v) => !!v.match(/(?=.*[!@#$%^&*()\-_=|№;:?}{])(?=.*[a-z])(?=.*[A-Z])/g) || 'Пароль должен содержать буквы разных регистров, а также спец. символы',
        ]
      },
      suggestions: {
        inn: null,
      },
      showPassword: false,
      fields: JSON.parse(JSON.stringify(this.values)),
    };
  },
  computed: {
    ...mapGetters({
      pending: 'user/pending',
    }),
    sid() {
      return last(split(this.img, 'captcha_sid='));
    },
    tax() {
      return toString(get(this.fields, ['UF_TAX_ID', 'values', 0], ''));
    },
    options() {
      return omitBy({
        UF_KPP: compact(map(this.suggestions.inn, 'data.kpp')),
        UF_OGRN: compact(map(this.suggestions.inn, 'data.ogrn')),
      }, isEmpty)
    },
    inn() {
      return toString(get(this.fields, ['UF_INN', 'values', 0], ''));
    },
    diff() {
      return JSON.stringify(this.values) !== JSON.stringify(this.fields);
    },
  },
  methods: {
    ...mapActions({
      createProfileDetail: 'user/createProfileDetail',
    }),
    onSave: function() {
      const captcha = this.captcha;
      const sid = this.sid;
      const values = this.fields;
      if (this.$refs.form.validate()) {
        this.createProfileDetail({ sid, captcha, values }).then(result => {
          if (result) {
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: get(result, 'message') || 'Проверьте почту указанную при регистрации',
            });
            this.$router.push({ path: '/' });
          }
        });
      }
    },
    onReset: function() {
      this.fields = JSON.parse(JSON.stringify(this.values));
    },
  },
  watch: {
    inn: {
      handler: function(query) {
        if (query && (query.length === 10 || query.length === 12)) {
          inn(query).then(({ suggestions }) => {
            this.suggestions.inn = suggestions;
            if (suggestions.length === 1) {
              const item = get(suggestions, 0);
              this.fields['UF_KPP']['values'] = [get(item, 'data.kpp')];
              this.fields['UF_OGRN']['values'] = [get(item, 'data.ogrn')];
            }
          });
        }
      }
    },
    values: {
      handler: function() {
        this.onReset();
      }
    }
  }
}
</script>

<style module lang="scss">
.root {
  :global(.v-expansion-panel) {
    border: 1px solid rgb(238, 238, 238);
  }
}
.progress {
  top: 0;
}
.header {
  font-weight: 500;
  font-size: 18px;
  background: #fff7e4;
  border-bottom: 1px solid #e6cfbe;
  padding: 5px 16px;
  margin: 0 -16px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
.label {
  font-weight: bold;
  font-size: 14px;
  color: #212121;
}
.title {
  font-weight: bold;
  font-size: 16px;
  color: #212121;
  margin-bottom: 15px;
}
.subscribe {
  cursor: pointer;
  margin-right: 10px;
}
.item {
  padding: 20px;
  margin: 0 -16px;
  & + & {
    border-top: 1px dashed rgba(0, 0, 0, 0.1);
  }
}
.link {
  color: #f57f29;
  text-decoration: underline;
}
.footer {
  padding: 20px 16px 0;
  margin: 0 -16px;
  border-top: 1px dashed rgba(0, 0, 0, 0.1);
}
</style>
