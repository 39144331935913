<template>
  <div :class="$style.root">
    <div :class="$style.nav">
      <VRow dense>
        <VCol cols="6">
          <VBtn color="primary" width="100%" large depressed outlined to="/">Авторизация</VBtn>
        </VCol>
        <VCol cols="6">
          <VBtn color="primary" width="100%" large depressed>Регистрация</VBtn>
        </VCol>
      </VRow>
    </div>
    <div :class="$style.inner">
      <Registration v-if="profile" :img="profile.captcha" :values="profile.values" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Registration from '@/components/user/Registration/Registration';

export default {
  name: 'UserRegistrationView',
  components: {
    Registration,
  },
  computed: {
    ...mapGetters({
      profile: 'user/profile',
    }),
  },
  methods: {
    ...mapActions({
      fetchEmptyProfileDetail: 'user/fetchEmptyProfileDetail',
    }),
  },
  mounted() {
    this.fetchEmptyProfileDetail();
  },
}
</script>

<style module lang="scss">
.root {
  max-width: 1200px;
  margin: auto;
}
.nav {
  margin: auto;
  max-width: 400px;
}
.inner {
  background: #fff;
  padding: 30px;
  margin-top: 20px;
  font-size: 14px;
}
.label {
  font-weight: 500;
  margin-bottom: 8px;
}
.control {
  text-align: center;
  font-size: 12px;
}
.link {
  color: #f57f29;
  text-decoration: underline;
}
</style>
